<template>
    <div class="blank">
    <div class="app-main">
    <CommonWorldHeader class="common-title" @openProfile="openProfile" :icon="icon" showProfile=""></CommonWorldHeader>
    <slot/>
    <v-dialog 
    class="me-dialog"
    v-model="showProfile"
    scrollable
    fullscreen
    >
    <v-card class="me-dialog">
        <PageProfileMe @closeProfile="closeProfile"></PageProfileMe>
    </v-card>
    </v-dialog>
    <v-overlay  :model-value="showInit" width="100%" height="100%" persistent>
        <v-progress-circular
        :size="50"
        color="red"
        indeterminate
        class="loading"
        fullscreen
        >
        <span class="loadtext">初期化</span>
        </v-progress-circular>
    </v-overlay>
    <div class="bottom">
      <div class="footer">
        <div class="left"></div>
        <div class="logo">
        <img class="center" src="~/assets/img/room/logo-mini.png" >
        </div>
      </div>
      <div class="copyright">
        <span class="large">©</span>
        <span>bokete battle</span>
      </div>
    </div>
    </div>
    </div>
</template>
<script setup>
const battle = useBattleStore();
const showInit = ref(true);
const route = useRoute()
useRecovery(battle, (loaded,data)=>{
    // console.log(data);
          if(data&&data.in_battle){
            console.log(route.name);
            if(route.name == 'world'||route.name == 'world-ranking'){
              navigateTo('/world/battle')
            }
          }
          showInit.value = false;
});

const showProfile = ref(false);

const config = useRuntimeConfig()

const icon = computed(() => {
    if(battle.auth.user&&battle.auth.user.icon){
        if(battle.auth.user.icon.indexOf("http") == 0){
            return battle.auth.user.icon;
        }
        return config.public.iconBaseUrl + battle.auth.user.icon;
    }else{
        return "/img/iconDefault.png";
    }
})

provide('showForme', openProfile);

function openProfile(){
    if(battle.auth.isAuthenticated){
        showProfile.value = true;
    }else{
        navigateTo('/world/login')
    }
}

function closeProfile(){
    showProfile.value = false;
}
</script>
<style lang="scss" scoped>
.blank{
  background-color: $base-room-color;
}
.app-main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 500px;
    min-height: 100dvh;
    margin: 0 auto;
    background-color: $base-room-color;
    // padding: 0 20px 20px 20px;
    padding: max(env(safe-area-inset-top, 20px),20px) max(env(safe-area-inset-right,20px),20px) max(env(safe-area-inset-bottom, 20px),20px) max(env(safe-area-inset-left,20px),20px);
    >.common-title{
        z-index:1;
    }
}
.loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
.loadtext{
  color: red;
  font-size: 10px;
  font-weight: bold;
}
:deep(.profile-dialog){
        >.dialog-background{
            background-color: #FFF;
            width: 100%;
            height: 100%;
        }
}
.bottom{
    position: sticky;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >.footer{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
     
      >.logo{ 
        flex-grow: 1;
        display: flex;
        justify-content: center;
      }
    }
    >.copyright{
        font-size: small;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        >.large{
          font-size: x-large;
        }
    }
  }
  .me-dialog{
        background: $base-room-color;;
        width: 100%;
        height: 100%;
  }

  .dialog-background{
    background-color: #FFF;
    width: 100%;
    height: 100%;
  }
</style>